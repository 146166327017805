import React, { useRef, useMemo } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import * as THREE from "three";
import "./App.css";

function NoisyRing() {
  const ringRef = useRef();
  const particlesRef = useRef();

  const particlesGeometry = useMemo(() => {
    const geometry = new THREE.BufferGeometry();
    const particleCount = 10000;
    const positions = new Float32Array(particleCount * 3);

    for (let i = 0; i < particleCount; i++) {
      const theta = Math.random() * Math.PI * 2;
      const r = THREE.MathUtils.randFloat(0.75, 2);
      positions[i * 3] = r * Math.cos(theta);
      positions[i * 3 + 1] = r * Math.sin(theta);
      positions[i * 3 + 2] = THREE.MathUtils.randFloatSpread(0.5);
    }

    geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
    return geometry;
  }, []);

  useFrame((state, delta) => {
    if (ringRef.current) {
      ringRef.current.rotation.z += delta * 0.1;
    }
    if (particlesRef.current) {
      particlesRef.current.rotation.z -= delta * 0.05;
    }
  });

  return (
    <group>
      <mesh ref={ringRef}>
        <torusGeometry args={[0.5, 0.2, 16, 100]} />
        <meshBasicMaterial color="#ffffff" />
      </mesh>
      <points ref={particlesRef} geometry={particlesGeometry}>
        <pointsMaterial
          size={0.005}
          color="#ffffff"
          transparent
          opacity={0.6}
          sizeAttenuation={true}
        />
      </points>
    </group>
  );
}

function App() {
  return (
    <div className="app">
      <Canvas camera={{ position: [0, 0, 5], fov: 60 }}>
        <color attach="background" args={["#000000"]} />
        <NoisyRing />
        <OrbitControls enableZoom={false} enablePan={false} />
      </Canvas>
    </div>
  );
}

export default App;
